import { Directive, ElementRef } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Directive({
  selector: '[appDisableControlOnMobile]'
})
export class DisableControlOnMobileDirective {
  constructor(
    el: ElementRef,
    private deviceDetector: DeviceDetectorService) {
    const mobileDevices = ['ipad', 'iphone', 'android'];
    const deviceType = this.deviceDetector.device;
    if (mobileDevices.includes(deviceType)) {
      el.nativeElement.setAttribute(
        'style',
        'display: none;'
      );
    }
  }
}
