import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromPipes from './pipes';
import * as fromComponents from './components';
import { MathService } from './services/math.service';
import { ImageResizeService } from './services/image-resize.service';

import { NotImplementedDirective } from './directives/notImplemented.directive';
import { DisableControlDirective } from './directives/disableControl.directive';
import { ShowIfAdminDirective } from './directives/showIfAdmin.directive';
import { ShowIfRoleDirective } from './directives/showIfRole.directive';
import { EnableControlForRoleDirective } from './directives/enableControlForRole.directive';
import { DisableControlOnMobileDirective } from './directives/disableControlOnMobile.directive';
import { GooglePlacesAutocompleteDirective } from './directives/google-places-autocomplete.directive';

const directives = [
  NotImplementedDirective,
  DisableControlDirective,
  ShowIfAdminDirective,
  ShowIfRoleDirective,
  EnableControlForRoleDirective,
  DisableControlOnMobileDirective,
  GooglePlacesAutocompleteDirective,
];
@NgModule({
  imports: [CommonModule],
  declarations: [
    ...fromPipes.pipes,
    ...directives,
    ...fromComponents.components
  ],
  exports: [
    ...fromPipes.pipes,
    ...directives,
    ...fromComponents.components
  ],
  providers: [MathService, ImageResizeService]
})
export class SharedModule {}
