import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appNotImplemented]'
})
export class NotImplementedDirective {
  constructor(el: ElementRef) {
    el.nativeElement.disabled = 'disabled';

    el.nativeElement.setAttribute(
      'class',
      el.nativeElement.getAttribute('class') + ' app-not-implemented'
    );
  }
}
